<template>
    <div>
        <header-portrate style="display:none" />

    <div class="m-2">
        <v-row class="m-2" style="border:1px solid #000;background:lightgoldenrodyellow">
            <posHeader />
            <posMenuButtons />
            <v-col cols="12" md="12" sm="12" class="mxmnheight" :style="`padding-top:0px;position:relative;direction:${lang.dir}`">
                
                <v-row class="mt-2">
                    <v-col cols="12" style="display:flex;justify-content:center;">
                        <div class="p-2 text-center" style="border:1px solid #000;width:200px;background:black !important;color:#fff !important">{{ lang.invoices }}</div>
                    </v-col>
                    <v-col
                    cols="12"
                    md="10"
                    sm="12">
                    <form autocomplete="off">
                        <v-row>
                            <v-col cols="12" md="2" sm="12">
                                <label>{{lang.start_date}}</label>
                                <b-input-group>
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                                </b-input-group>
                            </v-col>
                            <v-col cols="12" md="2" sm="12">
                                <label>{{lang.end_date}}</label>
                                <b-input-group>
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                                </b-input-group>
                            </v-col>
                            <v-col cols="12" md="2" sm="12">
                                <label></label>
                                <b-input-group style="margin-top:-5px">
                                <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getCurrentCards()">{{lang.search}}</b-button>
                                </b-input-group>
                            </v-col>
                        </v-row>
                        <b-input-group class="mt-3" style="display:none;">
                            <b-form-input placeholder="Start Date" type="date" style="border:1px solid #000;border-radius: 0;" autocomplete="off" v-model="sdate"></b-form-input>
                            <b-form-input placeholder="End Date"  type="date" style="border:1px solid #000" autocomplete="off" v-model="edate"></b-form-input>
                            <b-form-input :placeholder="lang.mobile" v-model="mobile" style="border:1px solid #000;font-weight:bolder;color:#000"></b-form-input>
                            <b-form-input :placeholder="lang.plate_number" style="border:1px solid #000;" v-if="$store.state.licenseType.cars" autocomplete="off" v-model="plate_number"></b-form-input>
                            <b-form-input :placeholder="lang.invoiceno" style="border:1px solid #000" autocomplete="off" v-model="invoice_number"></b-form-input>
                            <b-input-group-append style="border:1px solid #000">
                            <!-- <b-button variant="light" style="background:gold;"  @click="getCurrentCards()"><i class="fas fa-search"></i></b-button> -->
                            <b-button variant="light" style="background:black;color:#fff"  @click="getCurrentCards()">{{lang.search}}</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </form>
                    </v-col>
                    <!-- <v-col cols="12" md="4" sm="12" class="text-end" v-if="!this.$store.state.licenseType.isBill"> -->
                    
                    <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    style="display:none;"
                    >
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="lang.search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="md-3" :style="`direction:`+lang.dir+` ;`">
                    <v-data-table
                        :headers="headers"
                        :items="tablerowsF"
                        :search="search"
                        :items-per-page="15"
                        :page.sync="page"
                        width="100%"
                        id="MainTable"
                        hide-default-footer
                        @page-count="pageCount = $event" 
                        fixed-header
                    >
                        <template v-slot:item="row">
                        <tr>
                            <td style="width:50px;text-align:center;">{{ row.item.invoice_number }}</td>
                            <!-- <td :style="`border:2px solid #fff !important;width:50px;text-align:center;` + row.item._customer_type_style">{{ row.item._customer_type }}</td> -->
                            <td :style='`text-align:center;`'>{{ row.item._invtype }}</td>
                            <td class="text-center" nowrap v-if="row.item.customer_type == 1">{{ row.item.full_name }}</td>
                            <td class="text-center" nowrap v-if="row.item.customer_type == 2">{{ row.item.company_name }}</td>
                            <td class="text-center" nowrap v-if="row.item.customer_type == 3">{{ row.item.entity_name }}</td>
                            <td class="text-center">{{ row.item.mobile }}</td>
                            <!-- <td class="text-center" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">{{ row.item.plate_number }}</td>
                            <td class="text-center" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo || $store.state.licenseType.parts">{{ row.item.model }}</td> -->
                            <td class="text-center" nowrap>{{ row.item.inv_date.substr(0,10) }}</td>  
                            <td class="text-center" nowrap>{{ row.item.inv_date.substr(11,8) }}</td>  
                            <!-- <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.total) }}</td> -->
                            <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                            <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.vat,$store.state.licenseType.moneyComma) }}</td>
                            <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.paid,$store.state.licenseType.moneyComma) }}</td>
                            <td class="text-center">{{ $convertToComa(row.item.cash,$store.state.licenseType.moneyComma) }}</td>
                            <td class="text-center">{{ $convertToComa(row.item.span,$store.state.licenseType.moneyComma) }}</td>
                            <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.remain,$store.state.licenseType.moneyComma) }}</td>
                            <td class="text-center" v-if="$store.state.group_id == 1 && $store.state.licenseType.showAddedBy">{{ row.item._created_by }}</td>
                            <!-- <td :style='`border-left:1px solid #fff !important;width:100px;border:2px solid #FFF !important;text-align:center;color:#FFF !important;background:#`+row.item._statusbgColor'>{{ row.item._status }}</td> -->
                            <td style="text-align:center;background:red;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                <div class="viewBTN" style="background:red !important;" 
                                v-b-toggle.pview_invoice @click="getInvo(row.item)">
                                {{lang.return}}</div>
                            <!-- <v-btn style="font-size:0.2em;background:darkblue;width:100%;color:#fff;height:25px !important;margin-left:5px;" v-b-toggle.pview_invoice @click="getInvo(row.item)">{{lang.view}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></v-btn> -->
                            <!-- <v-btn v-if="row.item.status > 0" style="font-size:0.2em;background:red;width:50%;color:#FFF;height:25px !important;" @click="cancelInvocie(row.item)" v-b-toggle.view_invoice>ارجاع </v-btn> -->
                            </td>
                            <td style="text-align:center;background:green;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                <div class="viewBTN" 
                                style="background:green !important;" 
                                v-b-toggle.pview_invoice @click="getInvo(row.item)">
                                {{lang.print}}</div>
                            <!-- <v-btn style="font-size:0.2em;background:darkblue;width:100%;color:#fff;height:25px !important;margin-left:5px;" v-b-toggle.pview_invoice @click="getInvo(row.item)">{{lang.view}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></v-btn> -->
                            <!-- <v-btn v-if="row.item.status > 0" style="font-size:0.2em;background:red;width:50%;color:#FFF;height:25px !important;" @click="cancelInvocie(row.item)" v-b-toggle.view_invoice>ارجاع </v-btn> -->
                            </td>
                        </tr>
                        </template>
                        <template slot="body.append" v-if="$store.state.licenseType.showCardNums">
                                <tr class="black--text">
                                    <!-- <th class="backEmpty"></th> -->
                                    <th class="backEmpty"></th>
                                    <th class="backEmpty"></th>
                                    <th class="backEmpty"></th>
                                    <th class="backEmpty"></th>
                                    <!-- <th class="backEmpty" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo"></th>
                                    <th class="backEmpty" v-if="$store.state.licenseType.cars || $store.state.licenseType.parts || $store.state.licenseType.carinfo"></th> -->
                                    <th class="backBlack" style="font-size:1.2em;background:#7aa5da;text-align:center;">{{lang.totals}}</th>
                                    <!-- <th class="backGreen" style="font-size:1.2em;background:green;text-align:center;color:#fff">{{ $convertToComa(sumField('total')) }}</th> -->
                                    <th class="backBlack" style="font-size:1.2em;background:blue !important;text-align:center;">{{ $convertToComa(sumField('ftotal'),$store.state.licenseType.moneyComma) }}</th>
                                    <th class="backRed" style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ $convertToComa(sumField('vat'),$store.state.licenseType.moneyComma) }}</th>
                                    <th class="backGreen" style="font-size:1.2em;background:#000 !important;text-align:center;">{{ $convertToComa(sumField('paid'),$store.state.licenseType.moneyComma) }}</th>
                                    <th class="backBlack" style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ $convertToComa(sumField('cash'),$store.state.licenseType.moneyComma) }}</th>
                                    <th class="backBlack" style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ $convertToComa(sumField('span'),$store.state.licenseType.moneyComma) }}</th>
                                    <th class="backGold" style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ $convertToComa(sumField('remain'),$store.state.licenseType.moneyComma) }}</th>
                                    <th class="backEmpty" v-if="$store.state.licenseType.showAddedBy"></th>
                                    <th class="backEmpty"></th>
                                    <th class="backEmpty"></th>
                                </tr>
                        </template>
                    </v-data-table>
                    </v-col>
                    <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">

                    <v-container>
                        <div class="text-center pt-2">
                        <v-pagination
                            v-model="page"
                            value="15"
                            :length="pageCount"
                            color="#000"
                        ></v-pagination>
                        </div>
                    </v-container>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <vue-snotify></vue-snotify>
    </div>
    </div>
</template>

<script>
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import posMenuButtons from '@/components/pos-menuButtons.vue'
import posHeader from '@/components/pos-header.vue'
import {SnotifyPosition} from 'vue-snotify';
export default{
  components: { HeaderPortrate,posHeader,posMenuButtons },
    name: 'POS',
    data() {
        return {
            mobile: '',
            invoice_number: '',
            page: 1,
            pageCount: 15,
            tablerows: [],
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            search: '',
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        
        sdate: function() {
        return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
      },
      edate: function() {
        return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
      },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
      tablerowsF: function(){
        let t = [];
        for(let i=0;i<this.tablerows.length;i++){
          let s = {};
          Object.keys(this.tablerows[i]).forEach(key =>{
            if(key == 'total' || key == 'vat' || key == 'ftotal' || key == 'paid' || key == 'remain'){
              s[key] = parseFloat(this.tablerows[i][key]);
            }else{
              s[key] = this.tablerows[i][key]
            }
          })
          t.push(s)
        }
        return t;
      },
      headers: function () {
        let x =  [
            { 
              text: "#", align: 'center',
              filterable: false, value: 'invoice_number',
              sortable: false,class: 'backBlack'
            },
            // { 
            //   text: this.lang.customer_type, align: 'center',
            //   filterable: false, value: 'customer_type',
            //   sortable: false,class: 'backBlack'
            // },
            {
              text: this.lang.invtype, align: 'center',
              filterable: false, value: 'invtype',
              sortable: false,class: 'backBlack'
            },
            {
              text: this.lang.customer, align: 'center',
              filterable: false, value: 'full_name',
              sortable: false,class: 'backBlack'
            },
            {
              text: this.lang.mobile, align: 'center',
              filterable: true, value: 'mobile',
              sortable: false, class: 'backBlack'
            },
        ];
        // if(this.$store.state.licenseType.cars || this.$store.state.licenseType.carinfo){
        //   x.push({
        //       text: this.lang.plate, align: 'center',
        //       filterable: true, value: 'plate_number',
        //       sortable: false,class: 'backBlack'
        //     });
        //   x.push({
        //       text: this.lang.model, align: 'center',
        //       filterable: false, value: 'model',
        //       sortable: false,class: 'backBlack'
        //       });
        // }
        // else if(this.$store.state.licenseType.parts){
        //   x.push({
        //       text: this.lang.model, align: 'center',
        //       filterable: false, value: 'model',
        //       sortable: false,class: 'backBlack'
        //       });
        // }


          x.push({
            text: this.lang.inv_date, align: 'center',
            filterable: false, value: 'inv_date',
            sortable: false,class: 'backBlack'
          })
          x.push({
            text: this.lang.time, align: 'center',
            filterable: false, value: 'inv_date',
            sortable: false,class: 'backBlack'
          })
              ,
          // x.push({
          //     text: this.lang.total, align: 'center',
          //     filterable: false, value: 'total',
          //     sortable: false,class: 'backGreen'
          //   })
          x.push({
              text: this.lang.fftotal, align: 'center',
              filterable: false, value: 'ftotal',
              sortable: false,class: 'backBlue'
            })
          x.push({
              text: this.lang.vat, align: 'center',
              filterable: false, value: 'vat',
              sortable: false,class: 'backRed'
            });
          x.push({
              text: this.lang.paid, align: 'center',
              filterable: true, value: 'paid',
              sortable: false,class: 'backBlack'
            })
        x.push({
          text: this.lang.cash, align: 'center',
          filterable: true, value: 'cash',
          sortable: false,class: 'backBlack'
        })
        x.push({
          text: this.lang.span, align: 'center',
          filterable: true, value: 'span',
          sortable: false,class: 'backBlack'
        })
        x.push({
            text: this.lang.remain, align: 'center',
            filterable: true, value: 'remain',
            sortable: false,class: 'backGold'
          })
        if(this.$store.state.group_id == 1 && this.$store.state.licenseType.showAddedBy){
          x.push({
            text: this.lang.added_by, align: 'center', filterable: true,
            value: '_status', sortable: false,   class: 'backBlack'   
          });
        }
        // x.push({
        //     text: this.lang.status, align: 'center', filterable: true,
        //     value: '_status', sortable: false,class: 'backBlack'
        //   })
        x.push({
            text: this.lang.return, align: 'center',
            filterable: false, value: '',
            sortable: false,class: 'backBlack'
          });
        x.push({
            text: this.lang.print, align: 'center',
            filterable: false, value: '',
            sortable: false,class: 'backBlack'
          });
        
        return x;
      },
    },
    methods: {
        getCurrentCards(){
            const ds = new Date(this.sdate);
            const de = new Date(this.edate);
            if(ds.getTime() > de.getTime()){
                let message = "يجب ان يكون تاريخ البدء اقل من او يساوي تاريخ الانتهاء";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.invoice.payment.cash = this.full_ftotal
                if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
                return false;
            }
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            let post = new FormData();
            post.append('type','getAllInvoices');
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('auth',cook);
            let data = {
                datatype: 'all',
                sdate: this.sdate,
                edate: this.edate,
                mobile: '',
                plate_number: '',
                invoice_number: '',
            };
            post.append('data[datatype]',data.datatype);
            post.append('data[sdate]',data.sdate);
            post.append('data[edate]',data.edate);
            post.append('data[mobile]','');
            post.append('data[plate_number]','');
            post.append('data[invoice_number]','');
            // this.tablerows = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path,
            post
            ).then((response) => {
            const res = response.data;
                if(typeof res.error !== 'undefined' && res.error != null && typeof res.error.number !== 'undefined'
                && res.error.number != null && res.error.number == 200){
                    this.tablerows = res.results.data;
                    let p = parseInt(parseInt(res.results.data.length)/10);
                    this.page = p+1
                    console.log(this.page);
                }
            })
        },
    },
    created() {
       this.getCurrentCards();
    },
}
</script>

<style>
.scrollMe{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position:absolute;
    text-align:center;
    left:2px;
    width:50px;
    top:130px;
    bottom:40px;
}
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.invoicetable{
    max-height:calc(100vh - 325px);
    min-height:calc(100vh - 325px);
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
}
.mxmnheight{
    max-height:calc(100vh - 100px);
    min-height:calc(100vh - 100px);
    position: relative;
}
.mxmnheight-200{
    max-height:calc(100vh - 250px);
    overflow: auto;
}
.wp-100{
    width: 150px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:100px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#fff;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    /* background:#222;
    color:#fff; */
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
    height: 40px !important;
}
.btn-gray{
    background:#fff;
    color: #000;
    border-radius: 7px;
}
.btn-wght{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    position: relative;
    padding-top:25px !important;

}
.btn-wght:active,.btn-wght:hover,.btn-wght:visited{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    
}
.btn-orange,.btn-orange:active,.btn-orange:hover,.btn-orange:visited{
    background: #fd7e14 !important;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: #ffc107 !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
.rowPrice{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    background:green;
    color:#fff;
    text-align:center;
    padding:3px;
    border:1px solid #000;
}
.gradBG{
    background: rgb(31,43,153) !important;
background: linear-gradient(180deg, rgba(31,43,153,1) 0%, rgba(31,87,153,1) 3%, rgba(31,89,153,1) 18%, rgba(31,103,153,1) 50%, rgba(31,83,153,1) 82%, rgba(31,64,153,1) 97%, rgba(31,43,153,1) 100%) !important;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.scrollMe{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position:absolute;
    text-align:center;
    left:2px;
    width:50px;
    top:130px;
    bottom:40px;
}
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.invoicetable{
    max-height:calc(100vh - 325px);
    min-height:calc(100vh - 325px);
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
}
.mxmnheight{
    max-height:calc(100vh - 100px);
    min-height:calc(100vh - 100px);
    position: relative;
}
.mxmnheight-200{
    max-height:calc(100vh - 250px);
    overflow: auto;
}
.wp-100{
    width: 150px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:100px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#fff;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    /* background:#222;
    color:#fff; */
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
    height: 40px !important;
}
.btn-gray{
    background:#fff;
    color: #000;
    border-radius: 7px;
}
.btn-wght{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    position: relative;
    padding-top:25px !important;

}
.btn-wght:active,.btn-wght:hover,.btn-wght:visited{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    
}
.btn-orange,.btn-orange:active,.btn-orange:hover,.btn-orange:visited{
    background: #fd7e14 !important;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: #ffc107 !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
.rowPrice{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    background:green;
    color:#fff;
    text-align:center;
    padding:3px;
    border:1px solid #000;
}
.gradBG{
    background: rgb(31,43,153) !important;
background: linear-gradient(180deg, rgba(31,43,153,1) 0%, rgba(31,87,153,1) 3%, rgba(31,89,153,1) 18%, rgba(31,103,153,1) 50%, rgba(31,83,153,1) 82%, rgba(31,64,153,1) 97%, rgba(31,43,153,1) 100%) !important;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.border2black,.modal-content{
    border:2px solid #000 !important;
}
.positionEnd,.positionEnd:hover{
    background:transparent !important;
    border:none;
    box-shadow: none;
    color:#fff !important;
}
.flexit{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
}
.modal-header,.modal-footer{
    padding:5px 10px 0 0 !important;
    background:black !important ;
}
.modal-footer > * {
    margin:0;
}
.modal{
    top:40px;
}
.addButtom{
  position:absolute;
  text-align:center;
  left:0px;
}
</style>